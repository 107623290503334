import React from "react";
import { useLocation } from "react-router-dom";
import { useContext } from "react";
import Contexto from "../context/Contexto";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-accessible-accordion/dist/fancy-example.css";
import Temitas from "../components/Temitas";
import CursoFAQ from "./CursoFAQ";
import ImgMarcoBTN from "../components/ImgMarcoBTN";
import parse from "react-html-parser";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

function Curso() {
  const {
    productos,
    dameMiCurso,
    mandarMensajes,
    cliente,
    loguearUsuario,
    Griseado,
    cambiaPrecio,
  } = useContext(Contexto);
  const [cursoActual, setCursoActual] = useState(null);
  const [programa, setPrograma] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [precioF, setPrecioF] = useState(0);
  const [PrecioAjustado, SetPrecioAjustado] = useState(false);

  window.scrollTo(0, 0);
  useEffect(() => {
    //Busco en el array un objeto con el nombre que viene por url
    let resultado = productos.find(
      (element) => element.url === location.pathname
    );

    let path = location.pathname.split("/");

    //SI no es un curso a 404
    if (resultado === undefined && productos.length > 0 && path[1] != "ver") {
      navigate("/404");
    }
    setCursoActual(resultado);
  }, [productos]);
  let programaLive = [];
  if (cursoActual) {
    let claves = Object.keys(cursoActual.programa);
    for (let i = 0; i < claves.length; i++) {
      let clave = claves[i];
      programaLive.push(cursoActual.programa[clave]);
    }
  }
  let ranaloca = () => {
    console.log("keee", PrecioAjustado, cursoActual.url.substr(1));
    cambiaPrecio(cursoActual.url, PrecioAjustado);
  };
  useEffect(() => {
    if (cursoActual) {
      let precioFinal = cursoActual.precio;
      if (cursoActual.oferta !== "no") {
        let des = 100 - cursoActual.descuento;
        precioFinal = (cursoActual.precio * des) / 100;
        setPrecioF(precioFinal);
      } else {
        setPrecioF(precioFinal);
      }
    }
    // valido si el curso es logic le cambio el precio

    if (cursoActual && cursoActual.url.substr(1) == "logica") {
      if (cliente) {
        // if (
        //   cliente.hasOwnProperty("summerhack23") ||
        //   cliente.hasOwnProperty("summerhack2022") ||
        //   cliente.hasOwnProperty("summerhack2021") ||
        //   cliente.hasOwnProperty("summerhack2021feb") ||
        //   cliente.hasOwnProperty("winterhack") ||
        //   cliente.hasOwnProperty("designseason") ||
        //   cliente.hasOwnProperty("designseason2") ||
        //   cliente.hasOwnProperty("designseason3")
        // ) {
        //   SetPrecioAjustado(10000);
        // } else if (
        //   cliente.hasOwnProperty("js") ||
        //   cliente.hasOwnProperty("html") ||
        //   cliente.hasOwnProperty("python")
        // ) {
        //   SetPrecioAjustado(14000);
        // }
      }
    }
  }, [cursoActual, cliente]);

  return (
    <>
      {cursoActual && (
        <div className="cursocontenedor">
          <header
            className="header-curso"
            style={{ background: cursoActual.colorin }}
          >
            <div className="datosCurso">
              {cursoActual && (
                <h1 className="txtLetrita tituloCurso">{cursoActual.nombre}</h1>
              )}
              {cursoActual && cursoActual.grupo == "free" ? (
                <>
                  {cliente ? (
                    <button
                      onClick={() => {
                        dameMiCurso({ cursin: [cursoActual.url] });
                        mandarMensajes(
                          `Bien! te agregue ${cursoActual.nombre} ya podes verlo en tus cursos`
                        );
                      }}
                      className="btn-loco"
                    >
                      DAMELO GRATIS
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        loguearUsuario();
                      }}
                      className="btn-loco"
                    >
                      LOGUEATE Y PEDILO
                    </button>
                  )}
                </>
              ) : (
                <>
                  {cursoActual && (
                    <>
                      <h3 className="precioCurso txtLetrita">
                        AR$ {PrecioAjustado ? PrecioAjustado : precioF}
                      </h3>
                      {cursoActual.oferta !== "no" ||
                        (PrecioAjustado && (
                          <>
                            <p className="curso--oferta">
                              <del>AR$ {cursoActual.precio}</del>{" "}
                              {!PrecioAjustado
                                ? cursoActual.ofertaInfo
                                : PrecioAjustado}
                            </p>
                          </>
                        ))}
                      {!PrecioAjustado ? (
                        <ImgMarcoBTN
                          fill="#01ff00"
                          Hover="black"
                          width="100px"
                          height="30px"
                          nombre={cursoActual.url}
                          accion={"carrito"}
                          className="hand"
                        >
                          Comprar
                        </ImgMarcoBTN>
                      ) : (
                        <button onClick={ranaloca} className="hand">
                          Comprar!
                        </button>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
            <img
              src={`https://escueladevrock.com/${cursoActual.logo}`}
              alt={cursoActual.nombre}
            />
          </header>
          <section className="contenidoCurso">
            <p>{cursoActual && parse(cursoActual.longDescription)}</p>
            {!cursoActual.listadito ? (
              <>
                <div className="gridSobre">
                  <ul>
                    <li>
                      <p>
                        Empezalo y terminalo a tu tiempo{" "}
                        <span className="aclara">(*)</span>{" "}
                      </p>
                    </li>
                    <li>
                      <p>Curso completo</p>
                    </li>
                    <li>
                      <p>&nbsp;&nbsp;{cursoActual.cantidad}</p>
                    </li>
                    <li>
                      <p>{cursoActual.disponible}</p>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <p>
                        Miralo todas las veces que quieras{" "}
                        <span className="aclara">(*)</span>{" "}
                      </p>
                    </li>
                    <li>
                      <p>Desarrollá proyectos prácticos</p>
                    </li>
                    <li>
                      <p>Certificado digital</p>
                    </li>
                    <li>
                      <p>Modalidad online</p>
                    </li>

                    <li>
                      <p className="aclara">
                        (*) Lo tendrás disponible por 3, 6, 12 o 24 meses, según
                        la opción que elijas comprar, para verlo, volver a
                        mirarlo y repasar.
                      </p>
                    </li>
                  </ul>
                </div>
              </>
            ) : (
              <>
                <div> {parse(cursoActual.listadito)}</div>
              </>
            )}

            {cursoActual && (
              <>
                <CursoFAQ {...cursoActual.pregunta0}></CursoFAQ>
                <br />
                <CursoFAQ {...cursoActual.pregunta1}></CursoFAQ>
              </>
            )}
            <h1 className="txtLetrita">Programa Completo</h1>
            <Accordion>
              {programaLive.map((item, i) => {
                return (
                  <AccordionItem key={i}>
                    <AccordionItemHeading>
                      <AccordionItemButton>{item.nombre} </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <Temitas temas={item.temas}></Temitas>
                    </AccordionItemPanel>
                  </AccordionItem>
                );
              })}
            </Accordion>
          </section>
        </div>
      )}
    </>
  );
}

export default Curso;
