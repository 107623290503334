import React from "react";
import SummerDescuentos from "../components/SummerDescuentos25";
import { useContext } from "react";
import Contexto from "../context/Contexto";
import LogoSummer from "../assets/statics/summerhack24/summerhack24white.svg";
import FlappyBird from "../assets/statics/summerhack24/flappyBird.png";
import adicionales from "../assets/statics/summerhack24/adicionales.png";
import alumnxs2 from "../assets/statics/summerhack24/alumnxs.png";
import botPresentismo from "../assets/statics/summerhack24/bot-presentismo.png";
import certificados2 from "../assets/statics/summerhack24/certificados.png";
import chatDeConsultas from "../assets/statics/summerhack24/chat-de-consultas.png";
import clasesGrabadas from "../assets/statics/summerhack24/clases-grabadas.png";
import consultorios from "../assets/statics/summerhack24/consultorios.png";
import correccionesEnvivo from "../assets/statics/summerhack24/correcciones-envivo.png";
import emailsDiarios from "../assets/statics/summerhack24/emails-diarios.png";
import examenFinal from "../assets/statics/summerhack24/examen_final.png";
import logoFlappyBird from "../assets/statics/summerhack24/flappyBird.svg";
import inicio from "../assets/statics/summerhack24/inicio.png";
import instaPrivado from "../assets/statics/summerhack24/insta-privado.png";
import orientacion from "../assets/statics/summerhack24/orientacion.png";
import retosSemanales from "../assets/statics/summerhack24/retos-semanales.png";
import videos1a from "../assets/statics/summerhack24/videos1a.png";
import vivos from "../assets/statics/summerhack24/vivos.png";
import workbook from "../assets/statics/summerhack24/workbook.png";
import pez from "../assets/statics/summerhack24/pezSummer25.png";

const Summerhack2024 = () => {
  const { winter } = useContext(Contexto);

  const {
    adicional,
    alumnxs,
    bajada,
    certificados,
    descripcion,
    duracion,
    esparavos,
    insta,
    masters,
    mini,
    porquejs,
    porquejsres,
    quees,
    queesres,
    retos,
    videos,
    preguntas,
    preguntasDos,
    testimonios,
    retos_titulo,
    retos_txt,
    logica,
    equipo,
  } = winter;
  // eslint-disable-next-line
  const { history, dameMiCurso, gratis, url, toast } = winter;

  const { nombre, longDescription } = "";

  const handleCarrito = (e) => {
    console.log("Comprar");
  };

  return (
    <>
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta1/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-giJF6kkoqNQ00vy+HMDP7azOuL0xtbfIcaT9wjKHr8RbDVddVHyTfAAsrekwKmP1"
        crossorigin="anonymous"
      />
      <div className="todoSH24">
        <div className="hero">
          <img className="imgBird" src={pez} alt="" />
          <img className="imgPrincipal" src={LogoSummer} alt="" />
        </div>
        <div className="bajadita">
          <p className="">
            Sumate a este programa intensivo de <strong>3 meses</strong> y
            <strong> hackeá tu verano</strong>. Vas a aprender y a practicar
            todo lo que necesitás para ser parte del mundo del desarrollo web:
            <strong>HTML, CSS, JavaScript, React y Next.js</strong>.
          </p>
          {/* <img className="imgBirdTxt" src={logoFlappyBird} alt="" /> */}
          {/* <p className="bajadaTxt">
            Última preventa con descuento del 20% habilitada a partir del
            19/12/2023
          </p> */}
        </div>

        <div className="containerLoco">
          <div className="item">
            <img className="imgItem" src={inicio} alt="" />
            <p className="txtItem">
              3 meses, más de 50 videos, <strong>clases exclusivas</strong> y
              contenido creado específicamente para el programa.
            </p>
          </div>
          <div className="item">
            <img className="imgItem" src={clasesGrabadas} alt="" />
            <p className="txtItem">
              Más de 1.300 minutos de contenido para que veas{" "}
              <strong>
                cuando quieras, donde quieras, todas las veces que quieras
              </strong>
              .
            </p>
          </div>
          <div className="item">
            <img className="imgItem" src={videos1a} alt="" />
            <p className="txtItem">
              Vas a tener todo el
              <strong>contenido grabado disponible por un año</strong> a partir
              del comienzo de las clases.
            </p>
          </div>
          <div className="item">
            <img className="imgItem" src={retosSemanales} alt="" />
            <p className="txtItem">
              <strong>Retos</strong> para que pongas en práctica todo lo que vas
              aprendiendo durante el programa y empieces a crear tus proyectos.
            </p>
          </div>
          <div className="item">
            <img className="imgItem" src={adicionales} alt="" />
            <p className="txtItem">
              <strong>
                Podcast, encuentros semanales, comunidad de alumnxs
              </strong>{" "}
              y<strong>woorkbook</strong> de ejercicios para reforzar el
              contenido.
            </p>
          </div>
          <div className="item">
            <img className="imgItem" src={alumnxs2} alt="" />
            <p className="txtItem">
              Más de <strong>15 000 personas que ya eligieron CodeaRock</strong>{" "}
              y más de <strong>1500 que ya fueron parte</strong> del programa
              SummerHack.
            </p>
          </div>
          <div className="item">
            <img className="imgItem" src={certificados2} alt="" />
            <p className="txtItem">
              <strong>Certificado de finalización</strong> para que puedas
              acreditar tus conocimientos y sumarlos a tu perfil de LinkedIn.
            </p>
          </div>
          <div className="item">
            <img className="imgItem" src={vivos} alt="" />
            <p className="txtItem">
              Si te quedás con dudas, vas a poder participar de
              <strong>consultas semanales</strong>
              en vivo junto con tus compañerxs.
            </p>
          </div>
          <div className="item">
            <img className="imgItem" src={correccionesEnvivo} alt="" />
            <p className="txtItem">
              Todas las semanas Jony <strong>corrige y revisa en vivo</strong>{" "}
              por Discord los retos de lxs alumnxs y da sugerencias sobre los
              proyectos.
            </p>
          </div>
          <div className="item">
            <img className="imgItem" src={emailsDiarios} alt="" />
            <p className="txtItem">
              Todos los días vas a recibir un
              <strong>email con las instrucciones y tareas</strong>
              que tengas que completar para no atrasarte.
            </p>
          </div>
          <div className="item">
            <img className="imgItem" src={chatDeConsultas} alt="" />
            <p className="txtItem">
              <strong>Canal de Discord exclusivo</strong> para el programa donde
              podés enviar tus consultas y compartir con tus compañerxs.
            </p>
          </div>
          <div className="item">
            <img className="imgItem" src={instaPrivado} alt="" />
            <p className="txtItem">
              <strong>Cuenta de Instagram</strong> con tips, acompañamiento,
              consejos sobre cómo estudiar y ayuda para que te mantengas
              motivadx.
            </p>
          </div>
          <div className="item">
            <img className="imgItem" src={orientacion} alt="" />
            <p className="txtItem">
              <strong>Vivos</strong> para darte sugerencias sobre cómo conseguir
              tus primeros clientes y mucha info para que consigas trabajo.
            </p>
          </div>
          <div className="item">
            <img className="imgItem" src={workbook} alt="" />
            <p className="txtItem">
              <strong>Más de 40</strong> ejercicios de práctica para que tomes
              como referencia y empieces a desarrollar tus proyectos.
            </p>
          </div>
          <div className="item">
            <img className="imgItem" src={consultorios} alt="" />
            <p className="txtItem">
              <strong>Espacio en vivo</strong> por Discord para que puedas
              charlar con Jony y compartirle tus ideas, proyectos, dudas y
              dificultades.
            </p>
          </div>
          <div className="item">
            <img className="imgItem" src={examenFinal} alt="" />
            <p className="txtItem">
              Al finalizar los tres meses, vas a poder rendir un
              <strong>examen final</strong> sobre los temas vistos a lo largo
              del programa.
            </p>
          </div>
          <div className="item">
            <img className="imgItem" src={botPresentismo} alt="" />
            <p className="txtItem">
              El <strong>bot</strong> de la escuela va a estar
              <strong>tomando asistencia</strong> todos los días y recibiendo
              tus retos a través del canal privado.
            </p>
          </div>
        </div>
      </div>
      <SummerDescuentos></SummerDescuentos>
    </>
  );
};

export default Summerhack2024;
