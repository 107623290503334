import React from "react";
import { useContext, useEffect, useState } from "react";
import Contexto from "../context/Contexto";
import Footer from "./Footer";
import Header from "./Header";
import Loader from "./Loader";
import Entrar from "./Entrar";
import "../assets/css/Layout.css";
import toast, { Toaster } from "react-hot-toast";
import { useLocation } from "react-router-dom";

//import Transicion from "./Transicion";
import Crear from "./Crear";
import { useNavigate } from "react-router-dom";

function Layout(props) {
  const [mandate, setMandate] = useState([]);

  const { isLoader, aDondeVoy, Griseado, isEntrando, isCreando } =
    useContext(Contexto);
  const { children } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [haySummer, setHaySummer] = useState(false);

  useEffect(() => {
    // navigate(aDondeVoy);
    if (aDondeVoy !== null) {
      setMandate("todoGris");

      setTimeout(() => {
        navigate(aDondeVoy);
        setMandate("");
        Griseado(null);
      }, 500);
    } else {
      setMandate("");
    }
  }, [aDondeVoy]);
  useEffect(() => {
    if (
      location.pathname.substr(1) == "easterhack" ||
      location.pathname.substr(1) == "summerhack"
    ) {
      setHaySummer(true);
    } else {
    }
  }, []);

  return (
    <>
      {" "}
      {/* <Transicion></Transicion> */}
      <Toaster position="bottom-center" id="heyDevBot" />
      {isLoader && <Loader></Loader>}
      {isEntrando && <Entrar></Entrar>}
      {isCreando && <Crear></Crear>}
      <div className={`wraperTransition ${mandate}`}>
        <Header></Header>
        {haySummer ? children : <div className="container ">{children}</div>}
        <Footer></Footer>{" "}
      </div>
    </>
  );
}

export default Layout;
